import React, {Component} from "react";
import './CitySelector.css';

class CitySelector extends Component {
  constructor(props) {
    super(props);
    this.state = { state: null, expandedIso2: null };
  }
  
  show() {
    this.setState({ state: 'open' });
  }

  hide() {
    this.setState({ state: 'close' });
  }

  showCity(name, lat, lon) {
    this.props.setLocation(lat, lon, name);
    this.hide();
  }

  expandCountry(iso2) {
    this.setState(prevState => {
      return { expandedIso2: prevState.expandedIso2 === iso2 ? null : iso2 }
    });
  }

  countries = [
    { name: 'Afghanistan', iso2: 'AF', cities: [{ name: `Herat`, lat: 34.34, lon: 62.19 }, { name: `Kabul`, lat: 34.5166667, lon: 69.1833333 }, { name: `Kandahar`, lat: 31.61, lon: 65.7 }, { name: `Maz\u0101r-e Shar\u012Bf`, lat: 36.7069444, lon: 67.1122222 }]},
    { name: 'Algeria', iso2: 'DZ', cities: [{ name: `Algiers`, lat: 36.7630556, lon: 3.0505556 }, { name: `Bab Ezzouar`, lat: 36.7166667, lon: 3.1833333 }, { name: `Batna`, lat: 35.5559710497557, lon: 6.17414474487305 }, { name: `Constantine`, lat: 36.365, lon: 6.6147222 }, { name: `Oran`, lat: 35.6911111, lon: -0.6416667 }]},
    { name: 'Argentina', iso2: 'AR', cities: [{ name: `Buenos Aires`, lat: -34.5761256318848, lon: -58.4088134765625 }, { name: `C\u00F3rdoba`, lat: -31.4, lon: -64.1833333 }, { name: `La Plata`, lat: -34.9313889, lon: -57.9488889 }, { name: `Mar del Plata`, lat: -38.0, lon: -57.55 }, { name: `Mendoza`, lat: -32.8833333, lon: -68.8166667 }, { name: `Quilmes`, lat: -34.7202778, lon: -58.2694444 }, { name: `Rosario`, lat: -32.9511111, lon: -60.6663889 }, { name: `Salta`, lat: -24.7833333, lon: -65.4166667 }, { name: `San Miguel de Tucum\u00E1n`, lat: -26.8166667, lon: -65.2166667 }, { name: `Santa Fe de la Vera Cruz`, lat: -31.6333333, lon: -60.7 }]},
    { name: 'Australia', iso2: 'AU', cities: [{ name: `Adelaide`, lat: -34.9333333, lon: 138.6 }, { name: `Brisbane`, lat: -27.4679357321901, lon: 153.028092384338 }, { name: `Canberra`, lat: -35.2834624726481, lon: 149.128074645996 }, { name: `Gold Coast`, lat: -28.0, lon: 153.4333333 }, { name: `Melbourne`, lat: -37.8139965641595, lon: 144.963322877884 }, { name: `Newcastle`, lat: -32.9271483615446, lon: 151.776466369629 }, { name: `Perth`, lat: -31.9333333, lon: 115.8333333 }, { name: `Sydney`, lat: -33.8678499639382, lon: 151.207323074341 }, { name: `Wollongong`, lat: -34.4333333, lon: 150.8833333 }]},
    { name: 'Austria', iso2: 'AT', cities: [{ name: `Vienna`, lat: 48.2084877601653, lon: 16.3720750808716 }]},
    { name: 'Bangladesh', iso2: 'BD', cities: [{ name: `Chittagong`, lat: 22.3330556, lon: 91.8363889 }, { name: `Comilla`, lat: 23.4577778, lon: 91.2044444 }, { name: `Cox's B\u0101z\u0101r`, lat: 21.4422222, lon: 91.9780556 }, { name: `Dhaka`, lat: 23.7230556, lon: 90.4086111 }, { name: `Khulna`, lat: 22.8, lon: 89.55 }, { name: `Mymensingh`, lat: 24.75, lon: 90.4 }, { name: `Narsingdi`, lat: 23.9208333, lon: 90.7180556 }, { name: `R\u0101jsh\u0101hi`, lat: 24.3666667, lon: 88.6 }, { name: `Rangpur`, lat: 25.75, lon: 89.25 }, { name: `Tungi`, lat: 23.89, lon: 90.4058333 }]},
    { name: 'Belgium', iso2: 'BE', cities: [{ name: `Antwerp`, lat: 51.2166667, lon: 4.4166667 }, { name: `Brussels`, lat: 50.8465974826927, lon: 4.35277462005615 }]},
    { name: 'Brazil', iso2: 'BR', cities: [{ name: `Bel\u00E9m`, lat: -1.455833333, lon: -48.50444444 }, { name: `Belo Horizonte`, lat: -19.92083333, lon: -43.93777778 }, { name: `Bras\u00EDlia`, lat: -15.77972, lon: -47.92972 }, { name: `Curitiba`, lat: -25.42777778, lon: -49.27305556 }, { name: `Fortaleza`, lat: -3.717222222, lon: -38.54305556 }, { name: `Manaus`, lat: -3.101944444, lon: -60.025 }, { name: `Recife`, lat: -8.053888889, lon: -34.88111111 }, { name: `Rio de Janeiro`, lat: -22.90277778, lon: -43.2075 }, { name: `Salvador`, lat: -12.97111111, lon: -38.51083333 }, { name: `S\u00E3o Paulo`, lat: -23.5475, lon: -46.63611111 }]},
    { name: 'Burkina Faso', iso2: 'BF', cities: [{ name: `Bobo Dioulasso`, lat: 11.2, lon: -4.3 }, { name: `Ouagadougou`, lat: 12.3702778, lon: -1.5247222 }]},
    { name: 'Cambodia', iso2: 'KH', cities: [{ name: `Phnom Penh`, lat: 11.55, lon: 104.9166667 }]},
    { name: 'Cameroon', iso2: 'CM', cities: [{ name: `Bafoussam`, lat: 5.4666667, lon: 10.4166667 }, { name: `Bamenda`, lat: 5.9333333, lon: 10.1666667 }, { name: `Douala`, lat: 4.0502778, lon: 9.7 }, { name: `Garoua`, lat: 9.3, lon: 13.4 }, { name: `Kouss\u00E9ri`, lat: 12.0783333, lon: 15.0308333 }, { name: `Maroua`, lat: 10.5955556, lon: 14.3247222 }, { name: `Mokolo`, lat: 10.7402778, lon: 13.8027778 }, { name: `Yaound\u00E9`, lat: 3.8666667, lon: 11.5166667 }]},
    { name: 'Canada', iso2: 'CA', cities: [{ name: `Calgary`, lat: 51.050112282, lon: -114.085285152 }, { name: `Edmonton`, lat: 53.550135939, lon: -113.468712219 }, { name: `Hamilton`, lat: 43.233408354, lon: -79.949639869 }, { name: `Montr\u00E9al`, lat: 45.5088375, lon: -73.587809 }, { name: `North York`, lat: 43.766813883, lon: -79.416304667 }, { name: `Ottawa`, lat: 45.4209405046496, lon: -75.6902861595154 }, { name: `Qu\u00E9bec`, lat: 46.8122791164294, lon: -71.2145376205444 }, { name: `Toronto`, lat: 43.700113788, lon: -79.416304194 }, { name: `Vancouver`, lat: 49.249657393, lon: -123.119340403 }, { name: `Winnipeg`, lat: 49.884398624, lon: -97.147044715 }]},
    { name: 'Chad', iso2: 'TD', cities: [{ name: `NDjamena`, lat: 12.1130556, lon: 15.0491667 }]},
    { name: 'Chile', iso2: 'CL', cities: [{ name: `Antofagasta`, lat: -23.65, lon: -70.4 }, { name: `Santiago`, lat: -33.4262838490987, lon: -70.5665588378906 }, { name: `Talcahuano`, lat: -36.7166667, lon: -73.1166667 }, { name: `Valpara\u00EDso`, lat: -33.0393201968221, lon: -71.627254486084 }, { name: `vi\u00F1a causi\u00F1o`, lat: -33.6166667, lon: -70.5833333 }, { name: `Vi\u00F1a del Mar`, lat: -33.0245688893063, lon: -71.5518307685852 }]},
    { name: 'China', iso2: 'CN', cities: [{ name: `Beijing`, lat: 39.9074977414405, lon: 116.397228240967 }, { name: `Chengdu`, lat: 30.6666667, lon: 104.0666667 }, { name: `Chongqing`, lat: 29.5627778, lon: 106.5527778 }, { name: `Kaifeng`, lat: 34.7911111, lon: 114.3483333 }, { name: `Nanchong`, lat: 30.8, lon: 106.0666667 }, { name: `Shanghai`, lat: 31.2222222, lon: 121.4580556 }, { name: `Taian`, lat: 36.1852778, lon: 117.12 }, { name: `Tianjin`, lat: 39.1422222, lon: 117.1766667 }, { name: `Wuhan`, lat: 30.5833333, lon: 114.2666667 }, { name: `Zhumadian`, lat: 32.9794444, lon: 114.0294444 }]},
    { name: 'Colombia', iso2: 'CO', cities: [{ name: `Barranquilla`, lat: 10.9638889, lon: -74.7963889 }, { name: `Bogot\u00E1`, lat: 4.6, lon: -74.0833333 }, { name: `Bucaramanga`, lat: 7.1297222, lon: -73.1258333 }, { name: `Cali`, lat: 3.4372222, lon: -76.5225 }, { name: `Cartagena`, lat: 10.3997222, lon: -75.5144444 }, { name: `C\u00FAcuta`, lat: 7.8833333, lon: -72.5052778 }, { name: `Ibagu\u00E9`, lat: 4.4388889, lon: -75.2322222 }, { name: `Medell\u00EDn`, lat: 6.2913889, lon: -75.5361111 }, { name: `Pereira`, lat: 4.8133333, lon: -75.6961111 }, { name: `Santa Marta`, lat: 11.2472222, lon: -74.2016667 }]},
    { name: 'Cuba', iso2: 'CU', cities: [{ name: `Camag\u00FCey`, lat: 21.3808333, lon: -77.9169444 }, { name: `Guant\u00E1namo`, lat: 20.1444444, lon: -75.2091667 }, { name: `Havana`, lat: 23.1319444, lon: -82.3641667 }, { name: `Holgu\u00EDn`, lat: 20.8872222, lon: -76.2630556 }, { name: `Santa Clara`, lat: 22.4, lon: -79.9666667 }, { name: `Santiago de Cuba`, lat: 20.0247222, lon: -75.8219444 }]},
    { name: 'Czech Republic', iso2: 'CZ', cities: [{ name: `Brno`, lat: 49.1952226735148, lon: 16.6079592704773 }, { name: `Ostrava`, lat: 49.8346452000378, lon: 18.2820440791588 }, { name: `Praha`, lat: 50.0878367932108, lon: 14.4241322001241 }]},
    { name: 'Democratic Republic of the Congo', iso2: 'CD', cities: [{ name: `Kananga`, lat: -5.8958333, lon: 22.4177778 }, { name: `Kinshasa`, lat: -4.3297222, lon: 15.315 }, { name: `Kisangani`, lat: 0.5166667, lon: 25.2 }, { name: `Kolwezi`, lat: -10.7166667, lon: 25.4725 }, { name: `Likasi`, lat: -10.9813889, lon: 26.7333333 }, { name: `Lubumbashi`, lat: -11.6666667, lon: 27.4666667 }, { name: `Masina`, lat: -4.3836111, lon: 15.3913889 }, { name: `Mbuji-Mayi`, lat: -6.15, lon: 23.6 }, { name: `Tshikapa`, lat: -6.4166667, lon: 20.8 }]},
    { name: 'Denmark', iso2: 'DK', cities: [{ name: `Copenhagen`, lat: 55.6776812020993, lon: 12.5709342956543 }]},
    { name: 'Ecuador', iso2: 'EC', cities: [{ name: `Cuenca`, lat: -2.8833333, lon: -78.9833333 }, { name: `Guayaquil`, lat: -2.1666667, lon: -79.9 }, { name: `Quito`, lat: -0.2166667, lon: -78.5 }]},
    { name: 'Egypt', iso2: 'EG', cities: [{ name: `Al J\u012Bzah`, lat: 30.0086111, lon: 31.2122222 }, { name: `Al Ma\u1E29allah al Kubr\u00E1`, lat: 30.9761111, lon: 31.1669444 }, { name: `Al Man\u015F\u016Brah`, lat: 31.0430556, lon: 31.3766667 }, { name: `Alexandria`, lat: 31.1980556, lon: 29.9191667 }, { name: `Asy\u016B\u0163`, lat: 27.1827778, lon: 31.1827778 }, { name: `B\u016Br Sa\`\u012Bd`, lat: 31.2597699873943, lon: 32.3004913330078 }, { name: `Cairo`, lat: 30.05, lon: 31.25 }, { name: `Luxor`, lat: 25.6989270387148, lon: 32.6420974731445 }, { name: `Suez`, lat: 29.9666667, lon: 32.55 }, { name: `\u0162an\u0163\u0101`, lat: 30.7911111, lon: 30.9980556 }]},
    { name: 'Ethiopia', iso2: 'ET', cities: [{ name: `Addis Ababa`, lat: 9.0333333, lon: 38.7 }, { name: `Dir\u0113 Dawa`, lat: 9.5930556, lon: 41.8661111 }]},
    { name: 'Finland', iso2: 'FI', cities: [{ name: `Helsinki`, lat: 60.1755556, lon: 24.9341667 }]},
    { name: 'France', iso2: 'FR', cities: [{ name: `Lyon`, lat: 45.75, lon: 4.85 }, { name: `Marseille`, lat: 43.3, lon: 5.4 }, { name: `Nantes`, lat: 47.2172508193978, lon: -1.55336380004883 }, { name: `Nice`, lat: 43.703126104186, lon: 7.26608276367188 }, { name: `Paris`, lat: 48.85341, lon: 2.3488 }, { name: `Strasbourg`, lat: 48.5833333, lon: 7.75 }, { name: `Toulouse`, lat: 43.6042618680962, lon: 1.44367218017578 }]},
    { name: 'Germany', iso2: 'DE', cities: [{ name: `Berlin`, lat: 52.5166667, lon: 13.4 }, { name: `Bremen`, lat: 53.075155679403, lon: 8.80777359008789 }, { name: `Dortmund`, lat: 51.5166667, lon: 7.45 }, { name: `D\u00FCsseldorf`, lat: 51.2217225723832, lon: 6.77616119384766 }, { name: `Essen`, lat: 51.45, lon: 7.0166667 }, { name: `Frankfurt am Main`, lat: 50.1166667, lon: 8.6833333 }, { name: `Hamburg`, lat: 53.55, lon: 10.0 }, { name: `K\u00F6ln`, lat: 50.9333333, lon: 6.95 }, { name: `M\u00FCnchen`, lat: 48.1374325498109, lon: 11.5754914283752 }, { name: `Stuttgart`, lat: 48.7823242931971, lon: 9.17701721191406 }]},
    { name: 'Ghana', iso2: 'GH', cities: [{ name: `Accra`, lat: 5.55, lon: -0.2166667 }, { name: `Kumasi`, lat: 6.6833333, lon: -1.6166667 }, { name: `Tamale`, lat: 9.4, lon: -0.8333333 }]},
    { name: 'Greece', iso2: 'GR', cities: [{ name: `Athens`, lat: 37.9833333, lon: 23.7333333 }, { name: `Thessalon\u00EDki`, lat: 40.6402778, lon: 22.9438889 }]},
    { name: 'Guatemala', iso2: 'GT', cities: [{ name: `Guatemala City`, lat: 14.62111, lon: -90.52694 }, { name: `Mixco`, lat: 14.6333333, lon: -90.6063889 }, { name: `Villa Nueva`, lat: 14.5269444, lon: -90.5875 }]},
    { name: 'Guinea', iso2: 'GN', cities: [{ name: `Camayenne`, lat: 9.535, lon: -13.6877778 }, { name: `Conakry`, lat: 9.5091667, lon: -13.7122222 }]},
    { name: 'India', iso2: 'IN', cities: [{ name: `Ahmad\u0101b\u0101d`, lat: 23.0333333, lon: 72.6166667 }, { name: `Bengaluru`, lat: 12.9762266976805, lon: 77.6032912731171 }, { name: `Calcutta`, lat: 22.5697222, lon: 88.3697222 }, { name: `Chennai`, lat: 13.0878385345075, lon: 80.2784729003906 }, { name: `Delhi`, lat: 28.6666667, lon: 77.2166667 }, { name: `Hyder\u0101b\u0101d`, lat: 17.3752778, lon: 78.4744444 }, { name: `K\u0101npur`, lat: 26.4666667, lon: 80.35 }, { name: `Mumbai`, lat: 19.0144100168904, lon: 72.8479385375977 }, { name: `Pune`, lat: 18.5195742288075, lon: 73.8553547859192 }, { name: `S\u016Brat`, lat: 21.1666667, lon: 72.8333333 }]},
    { name: 'Indonesia', iso2: 'ID', cities: [{ name: `Bandung`, lat: -6.9127778, lon: 107.6205556 }, { name: `Bekasi`, lat: -6.2366667, lon: 106.9919444 }, { name: `Depok`, lat: -6.4, lon: 106.8186111 }, { name: `Jakarta`, lat: -6.1744444, lon: 106.8294444 }, { name: `Makassar`, lat: -5.1463889, lon: 119.4386111 }, { name: `Medan`, lat: 3.5833333, lon: 98.6666667 }, { name: `Palembang`, lat: -2.91672533277337, lon: 104.745798110962 }, { name: `Semarang`, lat: -6.9666667, lon: 110.4166667 }, { name: `Surabaya`, lat: -7.2491667, lon: 112.7508333 }, { name: `Tangerang`, lat: -6.1780556, lon: 106.63 }]},
    { name: 'Iraq', iso2: 'IQ', cities: [{ name: `Ab\u016B Ghurayb`, lat: 33.3083333, lon: 44.185 }, { name: `Al Ba\u015Frah`, lat: 30.4941667, lon: 47.8191667 }, { name: `Al Ba\u015Frah al Qad\u012Bmah`, lat: 30.4955556, lon: 47.8175 }, { name: `Al Maw\u015Fil al Jad\u012Bdah`, lat: 36.3291667, lon: 43.0861111 }, { name: `An Najaf`, lat: 31.9961111, lon: 44.3147222 }, { name: `Arb\u012Bl`, lat: 36.19, lon: 44.0088889 }, { name: `As Sulaym\u0101n\u012Byah`, lat: 35.5616667, lon: 45.4408333 }, { name: `Baghdad`, lat: 33.3386111, lon: 44.3938889 }, { name: `Kirkuk`, lat: 35.4680556, lon: 44.3922222 }, { name: `Mosul`, lat: 36.335, lon: 43.1188889 }]},
    { name: 'Italy', iso2: 'IT', cities: [{ name: `Bari`, lat: 41.117734, lon: 16.851185 }, { name: `Bologna`, lat: 44.4938114812334, lon: 11.3387489318848 }, { name: `Catania`, lat: 37.5021339578821, lon: 15.0871896743774 }, { name: `Florence`, lat: 43.7666667, lon: 11.25 }, { name: `Genova`, lat: 44.4063162526614, lon: 8.93385887145996 }, { name: `Milano`, lat: 45.4642693810258, lon: 9.1895055770874 }, { name: `Napoli`, lat: 40.8333333, lon: 14.25 }, { name: `Palermo`, lat: 38.115819808927, lon: 13.3597612380981 }, { name: `Roma`, lat: 41.9, lon: 12.4833333 }, { name: `Torino`, lat: 45.0704898496472, lon: 7.68682479858398 }]},
    { name: 'Ivory Coast', iso2: 'CI', cities: [{ name: `Abidjan`, lat: 5.3411111, lon: -4.0280556 }, { name: `Abobo`, lat: 5.4188889, lon: -4.0205556 }, { name: `Bouak\u00E9`, lat: 7.6833333, lon: -5.0330556 }]},
    { name: 'Japan', iso2: 'JP', cities: [{ name: `Fukuoka`, lat: 33.5833333, lon: 130.4 }, { name: `Kawasaki`, lat: 35.5205556, lon: 139.7172222 }, { name: `K\u014Dbe`, lat: 34.6833333, lon: 135.1666667 }, { name: `Kyoto`, lat: 35.02106999142, lon: 135.75385093689 }, { name: `Nagoya`, lat: 35.1814727966958, lon: 136.906409561634 }, { name: `\u014Csaka`, lat: 34.6937398415059, lon: 135.502181947231 }, { name: `Saitama`, lat: 35.9080659046769, lon: 139.656572341919 }, { name: `Sapporo`, lat: 43.0547222, lon: 141.3538889 }, { name: `Tokyo`, lat: 35.6895265930799, lon: 139.691677093506 }, { name: `Yokohama`, lat: 35.45, lon: 139.65 }]},
    { name: 'Kazakhstan', iso2: 'KZ', cities: [{ name: `Almaty`, lat: 43.25, lon: 76.95 }, { name: `Astana`, lat: 51.1811111, lon: 71.4277778 }, { name: `Karagandy`, lat: 50.0666667, lon: 54.8666667 }, { name: `Kyzylorda`, lat: 44.8527778, lon: 65.5091667 }, { name: `Pavlodar`, lat: 52.3, lon: 76.95 }, { name: `Qaraghandy`, lat: 49.7988889, lon: 73.0994444 }, { name: `Semipalatinsk`, lat: 50.4111111, lon: 80.2275 }, { name: `Shymkent`, lat: 42.3, lon: 69.6 }, { name: `Taraz`, lat: 42.9, lon: 71.3666667 }, { name: `Ust-Kamenogorsk`, lat: 49.9788889, lon: 82.6102778 }]},
    { name: 'Kenya', iso2: 'KE', cities: [{ name: `Mombasa`, lat: -4.05, lon: 39.6666667 }, { name: `Nairobi`, lat: -1.2833333, lon: 36.8166667 }, { name: `Nakuru`, lat: -0.2833333, lon: 36.0666667 }]},
    { name: 'Madagascar', iso2: 'MG', cities: [{ name: `Antananarivo`, lat: -18.9166667, lon: 47.5166667 }]},
    { name: 'Malawi', iso2: 'MW', cities: [{ name: `Blantyre`, lat: -15.7833333, lon: 35.0 }, { name: `Lilongwe`, lat: -13.9833333, lon: 33.7833333 }]},
    { name: 'Malaysia', iso2: 'MY', cities: [{ name: `Ipoh`, lat: 4.5833333, lon: 101.0833333 }, { name: `Johor Bahru`, lat: 1.4666667, lon: 103.75 }, { name: `Kampung Baru Subang`, lat: 3.15, lon: 101.5333333 }, { name: `Klang`, lat: 3.0333333, lon: 101.45 }, { name: `Kota Kinabalu`, lat: 5.9833333, lon: 116.0666667 }, { name: `Kuala Lumpur`, lat: 3.1666667, lon: 101.7 }, { name: `Kuching`, lat: 1.55, lon: 110.3333333 }, { name: `Petaling Jaya`, lat: 3.10726328338124, lon: 101.606712341309 }, { name: `Sandakan`, lat: 5.8333333, lon: 118.1166667 }, { name: `Shah Alam`, lat: 3.08506558481303, lon: 101.53281211853 }]},
    { name: 'Mali', iso2: 'ML', cities: [{ name: `Bamako`, lat: 12.65, lon: -8.0 }]},
    { name: 'Mexico', iso2: 'MX', cities: [{ name: `Ciudad Ju\u00E1rez`, lat: 31.7333333, lon: -106.4833333 }, { name: `Ciudad Nezahualc\u00F3yotl`, lat: 19.4136111, lon: -99.0330556 }, { name: `Ecatepec`, lat: 19.6011111, lon: -99.0525 }, { name: `Guadalajara`, lat: 20.6666667, lon: -103.3333333 }, { name: `Le\u00F3n`, lat: 21.1166667, lon: -101.6666667 }, { name: `Mexico City`, lat: 19.4341667, lon: -99.1386111 }, { name: `Monterrey`, lat: 25.6666667, lon: -100.3166667 }, { name: `Puebla de Zaragoza`, lat: 19.05, lon: -98.2 }, { name: `Tijuana`, lat: 32.5333333, lon: -117.0166667 }, { name: `Zapopan`, lat: 20.7166667, lon: -103.4 }]},
    { name: 'Morocco', iso2: 'MA', cities: [{ name: `Agadir`, lat: 30.4, lon: -9.6 }, { name: `Casablanca`, lat: 33.59, lon: -7.61 }, { name: `F\u00E8s`, lat: 34.05, lon: -4.98 }, { name: `Kenitra`, lat: 34.26, lon: -6.57 }, { name: `Marrakech`, lat: 31.63, lon: -8.0 }, { name: `Mekn\u00E8s`, lat: 33.9, lon: -5.55 }, { name: `Oujda`, lat: 34.68, lon: -1.91 }, { name: `Rabat`, lat: 34.02, lon: -6.83 }, { name: `Tangier`, lat: 35.78, lon: -5.81 }, { name: `Tetouan`, lat: 35.57, lon: -5.37 }]},
    { name: 'Mozambique', iso2: 'MZ', cities: [{ name: `Beira`, lat: -19.8436111, lon: 34.8388889 }, { name: `Chimoio`, lat: -19.1163889, lon: 33.4833333 }, { name: `Maputo`, lat: -25.9652778, lon: 32.5891667 }, { name: `Matola`, lat: -25.9622222, lon: 32.4588889 }, { name: `Nampula`, lat: -15.1197222, lon: 39.2647222 }]},
    { name: 'Nepal', iso2: 'NP', cities: [{ name: `Kathmandu`, lat: 27.7166667, lon: 85.3166667 }]},
    { name: 'Netherlands', iso2: 'NL', cities: [{ name: `Amsterdam`, lat: 52.3730839945403, lon: 4.89990234375 }, { name: `Den Haag`, lat: 52.076667, lon: 4.298611 }, { name: `Rotterdam`, lat: 51.9225, lon: 4.479167 }, { name: `Utrecht`, lat: 52.090833, lon: 5.122222 }]},
    { name: 'New Zealand', iso2: 'NZ', cities: [{ name: `Auckland`, lat: -36.8666667, lon: 174.7666667 }, { name: `Christchurch`, lat: -43.5333333, lon: 172.6333333 }, { name: `Manukau City`, lat: -36.9817120959048, lon: 174.870758056641 }, { name: `Wellington`, lat: -41.2785361586459, lon: 174.776639342308 }]},
    { name: 'Niger', iso2: 'NE', cities: [{ name: `Niamey`, lat: 13.5166667, lon: 2.1166667 }]},
    { name: 'Nigeria', iso2: 'NG', cities: [{ name: `Aba`, lat: 5.1166667, lon: 7.3666667 }, { name: `Benin-City`, lat: 6.3333333, lon: 5.6333333 }, { name: `Ibadan`, lat: 7.3877778, lon: 3.8963889 }, { name: `Jos`, lat: 9.9166667, lon: 8.9 }, { name: `Kaduna`, lat: 10.5230556, lon: 7.4402778 }, { name: `Kano`, lat: 11.9963889, lon: 8.5166667 }, { name: `Lagos`, lat: 6.4530556, lon: 3.3958333 }, { name: `Maiduguri`, lat: 11.845, lon: 13.16 }, { name: `Port Harcourt`, lat: 4.7891667, lon: 6.9986111 }, { name: `Zaria`, lat: 11.0666667, lon: 7.7 }]},
    { name: 'North Korea', iso2: 'KP', cities: [{ name: `Chongjin`, lat: 41.7955556, lon: 129.7758333 }, { name: `Hamh\u016Dng`, lat: 39.9183333, lon: 127.5363889 }, { name: `H\u016Dngnam`, lat: 39.8316667, lon: 127.6186111 }, { name: `Kaes\u014Fng`, lat: 37.9708333, lon: 126.5544444 }, { name: `Namp\u2019o`, lat: 38.7375, lon: 125.4077778 }, { name: `Pyongyang`, lat: 39.0194444, lon: 125.7547222 }, { name: `Sin\u016Diju`, lat: 40.1005556, lon: 124.3980556 }, { name: `W\u014Fnsan`, lat: 39.1527778, lon: 127.4436111 }]},
    { name: 'Pakistan', iso2: 'PK', cities: [{ name: `Faisal\u0101b\u0101d`, lat: 31.4166667, lon: 73.0833333 }, { name: `Gujr\u0101nw\u0101la`, lat: 32.15, lon: 74.1833333 }, { name: `Hyder\u0101b\u0101d`, lat: 25.3666667, lon: 68.3666667 }, { name: `Islamabad`, lat: 33.6100445736956, lon: 73.05908203125 }, { name: `Kar\u0101chi`, lat: 24.8666667, lon: 67.05 }, { name: `Lahore`, lat: 31.5497222, lon: 74.3436111 }, { name: `Mult\u0101n`, lat: 30.1955556, lon: 71.4752778 }, { name: `Pesh\u0101war`, lat: 34.0077778, lon: 71.5733333 }, { name: `Quetta`, lat: 30.1872222, lon: 67.0125 }, { name: `R\u0101walpindi`, lat: 33.6, lon: 73.0666667 }]},
    { name: 'Peru', iso2: 'PE', cities: [{ name: `Arequipa`, lat: -16.3988889, lon: -71.535 }, { name: `Callao`, lat: -12.0666667, lon: -77.15 }, { name: `Chiclayo`, lat: -6.7736111, lon: -79.8416667 }, { name: `Chimbote`, lat: -9.0852778, lon: -78.5783333 }, { name: `Cusco`, lat: -13.5183333, lon: -71.9780556 }, { name: `Huancayo`, lat: -12.0666667, lon: -75.2333333 }, { name: `Iquitos`, lat: -3.7480556, lon: -73.2472222 }, { name: `Lima`, lat: -12.05, lon: -77.05 }, { name: `Piura`, lat: -5.2, lon: -80.6333333 }, { name: `Trujillo`, lat: -8.1159942774572, lon: -79.0299797058105 }]},
    { name: 'Philippines', iso2: 'PH', cities: [{ name: `Antipolo`, lat: 14.5863889, lon: 121.1752778 }, { name: `Cagayan de Oro`, lat: 8.4822222, lon: 124.6472222 }, { name: `Cebu City`, lat: 10.3111111, lon: 123.8916667 }, { name: `Dasmari\u00F1as`, lat: 14.3294444, lon: 120.9366667 }, { name: `Davao`, lat: 7.0730556, lon: 125.6127778 }, { name: `Iloilo`, lat: 10.6969444, lon: 122.5644444 }, { name: `Manila`, lat: 14.6041667, lon: 120.9822222 }, { name: `Mansilingan`, lat: 10.6311111, lon: 122.9788889 }, { name: `San Jose del Monte`, lat: 14.8138889, lon: 121.0452778 }, { name: `Zamboanga`, lat: 6.9102778, lon: 122.0738889 }]},
    { name: 'Poland', iso2: 'PL', cities: [{ name: `Bydgoszcz`, lat: 53.15, lon: 18.0 }, { name: `Gda\u0144sk`, lat: 54.35, lon: 18.6666667 }, { name: `Katowice`, lat: 50.2666667, lon: 19.0166667 }, { name: `Krak\u00F3w`, lat: 50.0833333, lon: 19.9166667 }, { name: `Lublin`, lat: 51.25, lon: 22.5666667 }, { name: `Pozna\u0144`, lat: 52.4166667, lon: 16.9666667 }, { name: `Szczecin`, lat: 53.4293784371188, lon: 14.5529365539551 }, { name: `Warsaw`, lat: 52.25, lon: 21.0 }, { name: `Wroc\u0142aw`, lat: 51.1, lon: 17.0333333 }, { name: `\u0141\u00F3d\u017A`, lat: 51.75, lon: 19.4666667 }]},
    { name: 'Portugal', iso2: 'PT', cities: [{ name: `Lisbon`, lat: 38.7166667, lon: -9.1333333 }]},
    { name: 'Romania', iso2: 'RO', cities: [{ name: `Brass\u00F3`, lat: 45.6486083838803, lon: 25.6061267852783 }, { name: `Bucure\u015Fti`, lat: 44.4333333, lon: 26.1 }, { name: `Cluj-Napoca / Kolozsv\u00E1r`, lat: 46.7666667, lon: 23.6 }, { name: `Constan\u0163a`, lat: 44.1833333, lon: 28.65 }, { name: `Craiova`, lat: 44.3166667, lon: 23.8 }, { name: `Gala\u0163i`, lat: 45.45, lon: 28.05 }, { name: `J\u00E1szv\u00E1s\u00E1r`, lat: 47.1666667, lon: 27.6 }, { name: `Timi\u015Foara / Temesv\u00E1r`, lat: 45.7494444, lon: 21.2272222 }]},
    { name: 'Russia', iso2: 'RU', cities: [{ name: `Chelyabinsk`, lat: 55.1544444, lon: 61.4297222 }, { name: `Kazan`, lat: 55.75, lon: 49.1333333 }, { name: `Moscow`, lat: 55.7522222, lon: 37.6155556 }, { name: `Nizhniy Novgorod`, lat: 56.3286733202717, lon: 44.002046585083 }, { name: `Novosibirsk`, lat: 55.0411111, lon: 82.9344444 }, { name: `Omsk`, lat: 55.0, lon: 73.4 }, { name: `Rostov-na-Donu`, lat: 47.2363889, lon: 39.7138889 }, { name: `Saint Petersburg`, lat: 59.8944444, lon: 30.2641667 }, { name: `Samara`, lat: 53.2, lon: 50.15 }, { name: `Yekaterinburg`, lat: 56.8575, lon: 60.6125 }]},
    { name: 'Rwanda', iso2: 'RW', cities: [{ name: `Kigali`, lat: -1.9536111, lon: 30.0605556 }]},
    { name: 'Saudi Arabia', iso2: 'SA', cities: [{ name: `Ad Damm\u0101m`, lat: 26.4258333, lon: 50.1141667 }, { name: `A\u0163 \u0162\u0101if`, lat: 21.2702778, lon: 40.4158333 }, { name: `Buraydah`, lat: 26.3316667, lon: 43.9716667 }, { name: `Jiddah`, lat: 21.5169444, lon: 39.2191667 }, { name: `Kham\u012Bs Mushayt`, lat: 18.3063889, lon: 42.7291667 }, { name: `Mecca`, lat: 21.4266667, lon: 39.8261111 }, { name: `Medina`, lat: 24.4686111, lon: 39.6141667 }, { name: `Riyadh`, lat: 24.6408333, lon: 46.7727778 }, { name: `Sul\u0163\u0101nah`, lat: 24.5, lon: 39.5833333 }, { name: `Tab\u016Bk`, lat: 28.3833333, lon: 36.5833333 }]},
    { name: 'Senegal', iso2: 'SN', cities: [{ name: `Dakar`, lat: 14.669508133051474, lon: -17.43205189704895 }, { name: `Thi\u00E8s`, lat: 14.790941004207443, lon: -16.92692756652832 }, { name: `Touba`, lat: 14.862505109695116, lon: -15.875415802001953 }, { name: `Kaolack`, lat: 14.139405502082578, lon: -16.076345443725586 }, { name: `Ziguinchor`, lat: 12.58078483974076, lon: -16.267662048339844}]},
    { name: 'Serbia', iso2: 'RS', cities: [{ name: `Belgrade`, lat: 44.8186111, lon: 20.4680556 }, { name: `Pristina`, lat: 42.6666667, lon: 21.1666667 }]},
    { name: 'South Africa', iso2: 'ZA', cities: [{ name: `Benoni`, lat: -26.1833333, lon: 28.3166667 }, { name: `Cape Town`, lat: -33.9166667, lon: 18.4166667 }, { name: `Durban`, lat: -29.85, lon: 31.0166667 }, { name: `Johannesburg`, lat: -26.2, lon: 28.0833333 }, { name: `Pietermaritzburg`, lat: -29.6166667, lon: 30.3833333 }, { name: `Port Elizabeth`, lat: -33.9666667, lon: 25.5833333 }, { name: `Pretoria`, lat: -25.7069444, lon: 28.2294444 }, { name: `Soweto`, lat: -26.2666667, lon: 27.8666667 }, { name: `Tembisa`, lat: -25.9988889, lon: 28.2269444 }, { name: `Vereeniging`, lat: -26.6666667, lon: 27.9333333 }]},
    { name: 'South Korea', iso2: 'KR', cities: [{ name: `Inch\u014Fn`, lat: 37.4536111, lon: 126.7316667 }, { name: `Kwangju`, lat: 35.1547222, lon: 126.9155556 }, { name: `Puch\u014Fn`, lat: 37.4988889, lon: 126.7830556 }, { name: `Pusan`, lat: 35.1027778, lon: 129.0402778 }, { name: `Seoul`, lat: 37.5663889, lon: 126.9997222 }, { name: `S\u014Fngnam`, lat: 37.4386111, lon: 127.1377778 }, { name: `Suw\u014Fn`, lat: 37.2841667, lon: 127.0191667 }, { name: `Taegu`, lat: 35.8702778, lon: 128.5911111 }, { name: `Taej\u014Fn`, lat: 36.3213889, lon: 127.4197222 }, { name: `Ulsan`, lat: 35.5372222, lon: 129.3166667 }]},
    { name: 'Spain', iso2: 'ES', cities: [{ name: `Barcelona`, lat: 41.3887868890716, lon: 2.15898513793945 }, { name: `Bilbao`, lat: 43.2627062282001, lon: -2.9252815246582 }, { name: `Las Palmas de Gran Canaria`, lat: 28.1, lon: -15.4166667 }, { name: `Madrid`, lat: 40.4165020941502, lon: -3.70256423950195 }, { name: `M\u00E1laga`, lat: 36.7201559059499, lon: -4.42034482955933 }, { name: `Murcia`, lat: 37.9833333, lon: -1.1166667 }, { name: `Palma`, lat: 39.5693907933761, lon: 2.65023708343506 }, { name: `Sevilla`, lat: 37.3772222, lon: -5.9869444 }, { name: `Valencia`, lat: 39.4697524227712, lon: -0.377386808395386 }, { name: `Zaragoza`, lat: 41.6560643302881, lon: -0.877339839935303 }]},
    { name: 'Sri Lanka', iso2: 'LK', cities: [{ name: `Colombo`, lat: 6.9319444, lon: 79.8477778 }]},
    { name: 'Sudan', iso2: 'SD', cities: [{ name: `Al F\u0101shir`, lat: 13.6333333, lon: 25.35 }, { name: `Al Ubayyi\u1E11`, lat: 13.1833333, lon: 30.2166667 }, { name: `Kassala`, lat: 15.4558333, lon: 36.3988889 }, { name: `Khartoum`, lat: 15.5880556, lon: 32.5341667 }, { name: `K\u016Bst\u012B`, lat: 13.1666667, lon: 32.6666667 }, { name: `Omdurman`, lat: 15.6361111, lon: 32.4372222 }, { name: `Port Sudan`, lat: 19.6158333, lon: 37.2163889 }, { name: `Wad Madan\u012B`, lat: 14.4, lon: 33.5333333 }]},
    { name: 'Syria', iso2: 'SY', cities: [{ name: `Aleppo`, lat: 36.2027778, lon: 37.1586111 }, { name: `Damascus`, lat: 33.5, lon: 36.3 }, { name: `\u1E28am\u0101h`, lat: 35.1333333, lon: 36.75 }, { name: `\u1E28im\u015F`, lat: 34.7333333, lon: 36.7166667 }, { name: `Latakia`, lat: 35.5166667, lon: 35.7833333 }]},
    { name: 'Taiwan', iso2: 'TW', cities: [{ name: `Chi-lung`, lat: 25.1313889, lon: 121.7375 }, { name: `Hualian`, lat: 23.9769444, lon: 121.6044444 }, { name: `Kao-hsiung`, lat: 22.6177778, lon: 120.3013889 }, { name: `Tai-chung-shih`, lat: 24.1483333, lon: 120.6866667 }, { name: `Tainan City`, lat: 23.0247638077913, lon: 120.168113708496 }, { name: `Taipei`, lat: 25.0391667, lon: 121.525 }]},
    { name: 'Thailand', iso2: 'TH', cities: [{ name: `Bangkok`, lat: 13.75, lon: 100.5166667 }, { name: `Samut Prakan`, lat: 13.5993363664207, lon: 100.596753358841 }]},
    { name: 'Tunisia', iso2: 'TN', cities: [{ name: `Sfax`, lat: 34.7405556, lon: 10.7602778 }, { name: `Tunis`, lat: 36.8027778, lon: 10.1797222 }]},
    { name: 'Turkey', iso2: 'TR', cities: [{ name: `Adana`, lat: 37.0016667, lon: 35.3288889 }, { name: `Ankara`, lat: 39.9272222, lon: 32.8644444 }, { name: `Antalya`, lat: 36.9125, lon: 30.6897222 }, { name: `Bursa`, lat: 40.1916667, lon: 29.0611111 }, { name: `\u00C7ankaya`, lat: 39.9178995306752, lon: 32.8626823425293 }, { name: `Diyarbak\u0131r`, lat: 37.9188889, lon: 40.2105556 }, { name: `Gaziantep`, lat: 37.0594444, lon: 37.3825 }, { name: `\u0130stanbul`, lat: 41.0138429552247, lon: 28.9496612548828 }, { name: `\u0130zmir`, lat: 38.4072222, lon: 27.1502778 }, { name: `Konya`, lat: 37.8655556, lon: 32.4825 }]},
    { name: 'Uganda', iso2: 'UG', cities: [{ name: `Kampala`, lat: 0.3155556, lon: 32.5655556 }]},
    { name: 'Ukraine', iso2: 'UA', cities: [{ name: `Dnipropetrovsk`, lat: 48.45, lon: 34.9833333 }, { name: `Donetsk`, lat: 48.0, lon: 37.8 }, { name: `Kharkiv`, lat: 50.0, lon: 36.25 }, { name: `Kiev`, lat: 50.4333333, lon: 30.5166667 }, { name: `Kryvyy Rih`, lat: 47.9166667, lon: 33.35 }, { name: `Lviv`, lat: 49.8382592353251, lon: 24.0232372283936 }, { name: `Mariupol`, lat: 47.0666667, lon: 37.5 }, { name: `Mykolayiv`, lat: 46.9666667, lon: 32.0 }, { name: `Odesa`, lat: 46.4774726081453, lon: 30.7326221466064 }, { name: `Zaporizhzhya`, lat: 47.8166667, lon: 35.1833333 }]},
    { name: 'United Kingdom', iso2: 'GB', cities: [{ name: `Birmingham`, lat: 52.4666667, lon: -1.9166667 }, { name: `Bristol`, lat: 51.45, lon: -2.5833333 }, { name: `Edinburgh`, lat: 55.95, lon: -3.2 }, { name: `Glasgow`, lat: 55.8651497187961, lon: -4.25763130187988 }, { name: `Leeds`, lat: 53.7964812592701, lon: -1.54784917831421 }, { name: `Leicester`, lat: 52.6333333, lon: -1.1333333 }, { name: `Liverpool`, lat: 53.410580680167, lon: -2.97793865203857 }, { name: `London`, lat: 51.5084152563931, lon: -0.125532746315002 }, { name: `Manchester`, lat: 53.4809464283615, lon: -2.23743438720703 }, { name: `Sheffield`, lat: 53.3829700049879, lon: -1.46590232849121 }]},
    { name: 'United States', iso2: 'US', cities: [{ name: `Brooklyn`, lat: 40.6501038, lon: -73.9495823 }, { name: `Chicago`, lat: 41.850033, lon: -87.6500523 }, { name: `Dallas`, lat: 32.7830556, lon: -96.8066667 }, { name: `Houston`, lat: 29.7632836, lon: -95.3632715 }, { name: `Los Angeles`, lat: 34.0522342, lon: -118.2436849 }, { name: `New York City`, lat: 40.7142691, lon: -74.0059729 }, { name: `Philadelphia`, lat: 39.952335, lon: -75.163789 }, { name: `Phoenix`, lat: 33.4483771, lon: -112.0740373 }, { name: `San Antonio`, lat: 29.4241219, lon: -98.4936282 }, { name: `San Diego`, lat: 32.7153292, lon: -117.1572551 }]},
    { name: 'Uzbekistan', iso2: 'UZ', cities: [{ name: `Andijon`, lat: 40.7830556, lon: 72.3438889 }, { name: `Namangan`, lat: 40.9982978489732, lon: 71.6725730895996 }, { name: `Samarqand`, lat: 39.6541667, lon: 66.9597222 }, { name: `Tashkent`, lat: 41.3166667, lon: 69.25 }]},
    { name: 'Venezuela', iso2: 'VE', cities: [{ name: `Barcelona`, lat: 10.1333333, lon: -64.7 }, { name: `Barquisimeto`, lat: 10.0738889, lon: -69.3227778 }, { name: `Caracas`, lat: 10.5, lon: -66.9166667 }, { name: `Ciudad Guayana`, lat: 8.3533333, lon: -62.6527778 }, { name: `Maracaibo`, lat: 10.6316667, lon: -71.6405556 }, { name: `Matur\u00EDn`, lat: 9.75, lon: -63.1766667 }, { name: `Petare`, lat: 10.4833333, lon: -66.8166667 }, { name: `Puerto La Cruz`, lat: 10.2166667, lon: -64.6166667 }, { name: `Turmero`, lat: 10.2333333, lon: -67.4833333 }, { name: `Valencia`, lat: 10.1805556, lon: -68.0038889 }]},
    { name: 'Vietnam', iso2: 'VN', cities: [{ name: `Bi\u00EAn H\u00F2a`, lat: 10.95, lon: 106.8166667 }, { name: `C\u1EA7n Th\u01A1`, lat: 10.0333333, lon: 105.7833333 }, { name: `Da Nang`, lat: 16.0677778, lon: 108.2208333 }, { name: `H\u00E0 N\u1ED9i`, lat: 21.0333333, lon: 105.85 }, { name: `Haiphong`, lat: 20.8561111, lon: 106.6822222 }, { name: `Hu\u1EBF`, lat: 16.4666667, lon: 107.6 }, { name: `Nha Trang`, lat: 12.25, lon: 109.1833333 }, { name: `Th\u00E0nh ph\u1ED1 H\u1ED3 Ch\u00ED Minh`, lat: 10.75, lon: 106.6666667 }]},
    { name: 'Zambia', iso2: 'ZM', cities: [{ name: `Kitwe`, lat: -12.8166667, lon: 28.2 }, { name: `Lusaka`, lat: -15.4166667, lon: 28.2833333 }, { name: `Ndola`, lat: -12.9666667, lon: 28.6333333 }]},
    { name: 'Zimbabwe', iso2: 'ZW', cities: [{ name: `Bulawayo`, lat: -20.15, lon: 28.5833333 }, { name: `Chitungwiza`, lat: -17.9938889, lon: 31.0480556 }, { name: `Harare`, lat: -17.8177778, lon: 31.0447222 }]},
  ];

  render() {
    let className = '';
    if (this.state.state === 'open') {
      className = 'slide-in';
    } else if (this.state.state === 'close') {
      className = 'slide-out';
    }
    return (
      <div id="cityselector" className={className}>
        <h1>Major cities <a onClick={this.hide.bind(this)}><i className="fa fa-times"/></a></h1>
        <ol>
          {
            this.countries.map((country) =>
              <li key={country.iso2} className={this.state.expandedIso2 === country.iso2 ? 'active' : 'inactive'}><a className="country" onClick={this.expandCountry.bind(this, country.iso2)}><span className={'flag flag-' + country.iso2.toLowerCase()}/>{country.name}</a>
                <ol>
                  {
                    country.cities.map((city) =>
                      <li key={country.iso2 + city.name}><a onClick={this.showCity.bind(this, city.name, city.lat, city.lon)}>{city.name}</a></li>
                    )
                  }
                </ol>
              </li>
            )
          }
        </ol>
      </div>
    );
  }
}

export default CitySelector;
